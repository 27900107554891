import { HttpClient } from '@angular/common/http'
import {
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
  provideTransloco
} from '@jsverse/transloco'
import { Injectable, isDevMode, NgModule } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['en', 'es', 'de', 'fr'],
        defaultLang: 'es',
        fallbackLang: 'es',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        flatten: {
          aot: !isDevMode()
        }
      }),
      loader: TranslocoHttpLoader
    })
  ]
})
export class TranslocoRootModule {}
