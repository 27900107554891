import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '@gaditana/module-builder'

/**
 * Auth Guard
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  /**
   * Auth Guard constructor
   * @param store ngRx store state
   * @param router Router
   * @param auth AuthService
   */
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  /**
   * Can activate route
   * @returns logged in observable
   */
  canActivate(): boolean {
    if (!this.auth.isLogged) {
      localStorage.removeItem('auth')
      this.router.navigate(['/login'])
    }
    return this.auth.isLogged
  }

  /**
   * Can activate child route
   * @returns logged in observable
   */
  canActivateChild(): boolean {
    return this.canActivate()
  }
}
