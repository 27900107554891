import { Component, OnDestroy, OnInit } from '@angular/core'
import { ReplaySubject, take, takeUntil } from 'rxjs'

import { AuthService } from '@gaditana/module-builder'
import { PrimeNGConfig } from 'primeng/api'

import { TranslocoService } from '@jsverse/transloco'
import { getBrowserLang } from '@jsverse/transloco'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroySubject = new ReplaySubject<undefined | null>(undefined)

  constructor(
    private primeNgConfig: PrimeNGConfig,
    private translateService: TranslocoService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.translateService.langChanges$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.translateService
          .selectTranslateObject('primeng')
          .pipe(take(1))
          .subscribe((primeNgTranslations) => {
            this.primeNgConfig.setTranslation(primeNgTranslations)
          })
      })
    const browserLang = getBrowserLang() || 'es'
    const availableLangs = this.translateService.getAvailableLangs()
    const lang = availableLangs.includes(browserLang as any)
      ? browserLang
      : 'es'
    this.translateService.setActiveLang(lang)
    document.body.classList.add('light')
    this.authService.init()
  }

  ngOnDestroy(): void {
    this.destroySubject.next(null)
    this.destroySubject.complete()
  }
}
